/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { AllasStandaloneMarkup as ResolvedStandaloneMarkup } from 'sites/allas/components/standalone/Markup';

export const Markup = ResolvedStandaloneMarkup;

export type MarkupProps = PropsFromComponent<typeof Markup>;

export const MarkupWith = (extras: DynamicStandaloneExtras): typeof Markup => {
    return function Markup(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Markup');
        return <ResolvedStandaloneMarkup {...mergeProps({ options: { theme } }, props)} />;
    }
}