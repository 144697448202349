/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneCoverBox as ResolvedStandaloneCoverBox } from 'base/components/standalone/CoverBox';

export const CoverBox = ResolvedStandaloneCoverBox;

export type CoverBoxProps = PropsFromComponent<typeof CoverBox>;

export const CoverBoxWith = (extras: DynamicStandaloneExtras): typeof CoverBox => {
    return function CoverBox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CoverBox');
        return <ResolvedStandaloneCoverBox {...mergeProps({ options: { theme } }, props)} />;
    }
}