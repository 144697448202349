/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "label_colors_default": "",
  "label_size_default": "text-body-md ml-2",
  "label_variant_default": "",
  "input_colors_default": "border-gray-400 checked:bg-logga-600 outline-gray-100",
  "input_size_default": "h-5 w-5 p-1 border hover:outline-4 focus:outline-4 active:outline-0 checked:outline-0",
  "input_variant_default": "appearance-none bg-clip-content rounded-full hover:outline focus:outline",
  "colors_default": "",
  "size_default": "my-4",
  "variant_default": "flex items-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "RadioButton");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;