/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { AllasStandaloneAffiliateGallery as ResolvedStandaloneAffiliateGallery } from 'sites/allas/components/standalone/AffiliateGallery';

export const AffiliateGallery = ResolvedStandaloneAffiliateGallery;

export type AffiliateGalleryProps = PropsFromComponent<typeof AffiliateGallery>;

export const AffiliateGalleryWith = (extras: DynamicStandaloneExtras): typeof AffiliateGallery => {
    return function AffiliateGallery(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AffiliateGallery');
        return <ResolvedStandaloneAffiliateGallery {...mergeProps({ options: { theme } }, props)} />;
    }
}