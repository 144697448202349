import { StandaloneComponent } from '@/types/component';
import { StandaloneShowsSlider, StandaloneShowsSliderProps } from 'base/components/ShowsSlider';

export const AllasStandaloneShowsSlider: StandaloneComponent<StandaloneShowsSliderProps> = (props) => (
  <StandaloneShowsSlider
    shows={props.shows}
    options={{
      $swiper: {
        $content: {
          size: 'none',
        },
        spaceBetween: 12,
      },
    }}
  />
);
