/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_default": "flex flex-col items-start gap-y-1",
  "label_variant_default": "text-body-sm",
  "label_variant_floating": "absolute left-2.5 duration-300 transform scale-75 z-10 origin-[0] -translate-y-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-1 text-body-md top-2",
  "label_status_default": "text-gray-800",
  "label_status_error": "text-gray-800",
  "field_status_default": "placeholder:text-gray-500 hover:placeholder:text-black focus:placeholder:text-black border-gray-600 hover:border-black focus:border-logga-600 focus:outline-0",
  "field_status_error": "focus:outline focus:outline-4 focus:outline-offset-0 bg-error-99 placeholder:text-gray-500 hover:placeholder:text-black focus:placeholder:text-black border border-error-500 focus:outline-error-100",
  "field_variant_default": "block w-full resize-none box-border p-2.5 leading-6.5 border border-solid rounded appearance-none text-body-md",
  "field_variant_floating": "block w-full resize-none box-border p-2.5 leading-6.5 border border-solid rounded appearance-none focus:ring-0 peer placeholder:!text-transparent text-body-md pt-5",
  "field_size_default": "h-35 w-55",
  "field_group_variant_default": "flex flex-col-reverse gap-y-1",
  "field_group_variant_floating": "relative",
  "caption_variant_default": "text-body-sm",
  "caption_status_error": "text-error-500",
  "caption_group_size_default": "gap-2",
  "caption_group_variant_default": "flex items-center",
  "caption_group_status_error": "text-black",
  "caption_status_default": "text-gray-800"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TextArea");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;