import { default as BaseArticleHeaderTheme } from 'base/components/ArticleHeader/theme';

import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
  base: '',
  variants: {
    colors: {
      text: 'text-logga-700',
    },
    size: {
      text: 'font-medium',
    },
    variant: {
      text: 'inline-flex uppercase',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  slots: {
    ...BaseArticleHeaderTheme.slots,
  },
  variants: {
    variant: {
      first: {
        base: `mx-auto w-full max-w-content-lg gap-3`,
        bylines: ``,
        category: ``,
        description: `mt-4 text-preamble`,
        headline: `text-headline-sm sm:w-3/4 sm:text-headline-lg`,
        media: `[&_img]:aspect-default [&_img]:object-cover`,
        tags: ``,
        date: `text-body-md order-4 flex flex-col gap-1 self-center text-gray-500 sm:flex-row sm:gap-0`,
      },
    },
  },
  defaultVariants: {
    variant: 'first',
  },
});

export default Object.assign(ArticleHeader, { Tag });
