import { tw } from '@/utils/tw';
import BaseArticleDateTheme from 'base/components/ArticleDate/theme';

const ArticleDate = tw.theme({
  extend: BaseArticleDateTheme,
  slots: {
    base: ``,
    date: `text-gray-700 text-body-sm sm:text-body-md`,
    updatedDate: `text-gray-700 text-body-sm sm:text-body-md`,
  },
});

export default ArticleDate;
