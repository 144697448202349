import { ArticleTeaserBodytext } from '@/components/ArticleTeaser/Bodytext';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';

export const LabradorArticleTeaserBodytext: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();

  const { category, categoryUrl, title, publishedUrl, kicker, footer, options } = data ?? {};

  const labradorImage = descendants?.find((descendant) => descendant.type === 'labradorImage')?.data;

  const { imageUrl } = labradorImage ?? {};

  const src = imageUrl?.[device];

  return (
    <ArticleTeaserBodytext
      links={{
        article: { href: publishedUrl },
        category: { href: categoryUrl || category },
      }}
      headline={kicker}
      category={category}
      description={title}
      footer={footer}
      image={src}
      options={options}
      data-content-index={meta.contentIndex}
    />
  );
};
