import { StandaloneSavedArticles } from 'base/components/SavedArticles';
import mergeProps from 'lib/utils/mergeProps';

export const AllasStandaloneSavedArticles: typeof StandaloneSavedArticles = (props) => {
  return (
    <StandaloneSavedArticles
      {...mergeProps(
        {
          headline: 'Dina sparade artiklar',
          icon: { name: 'bin' },
        },
        props,
      )}
    />
  );
};
