/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneQuotebox as ResolvedStandaloneQuotebox } from 'base/components/standalone/Quotebox';

export const Quotebox = ResolvedStandaloneQuotebox;

export type QuoteboxProps = PropsFromComponent<typeof Quotebox>;

export const QuoteboxWith = (extras: DynamicStandaloneExtras): typeof Quotebox => {
    return function Quotebox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Quotebox');
        return <ResolvedStandaloneQuotebox {...mergeProps({ options: { theme } }, props)} />;
    }
}