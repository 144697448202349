/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "tags_variant_default": "flex flex-wrap",
  "tags_size_default": "gap-3 mx-auto md:max-w-article-lg w-full",
  "tags_colors_default": "",
  "tags_align_left": "",
  "tags_align_center": "justify-center",
  "line_variant_default": "",
  "line_size_default": "w-full h-px",
  "line_colors_default": "bg-gray-100",
  "variant_default": "flex flex-col",
  "size_default": "w-full gap-5.5 mt-6",
  "colors_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleFooter");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;