/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { AllasStandaloneInstagramArticlesList as ResolvedStandaloneInstagramArticlesList } from 'sites/allas/components/standalone/InstagramArticlesList';

export const InstagramArticlesList = ResolvedStandaloneInstagramArticlesList;

export type InstagramArticlesListProps = PropsFromComponent<typeof InstagramArticlesList>;

export const InstagramArticlesListWith = (extras: DynamicStandaloneExtras): typeof InstagramArticlesList => {
    return function InstagramArticlesList(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'InstagramArticlesList');
        return <ResolvedStandaloneInstagramArticlesList {...mergeProps({ options: { theme } }, props)} />;
    }
}