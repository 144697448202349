import { StandaloneArticleTeaserBodytext } from 'base/components/ArticleTeaser/Bodytext';
import mergeProps from 'lib/utils/mergeProps';

export const AllasStandaloneArticleTeaserBodytext: typeof StandaloneArticleTeaserBodytext = (props) => (
  <StandaloneArticleTeaserBodytext
    {...mergeProps(
      {
        options: {
          variant: 'fourth',
          $image: {
            variant: 'fourth',
          },
        },
      },
      props,
    )}
  />
);
