/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_default": "grid grid-cols-[min-content_auto_min-content] items-center whitespace-nowrap before:block after:block after:relative after:top-0.5 before:col-start-3 before:row-start-1 before:bg-no-repeat before:relative before:-top-1",
  "colors_default": "before:bg-[url(/image/allas-logo-logga-700.svg)] after:bg-black",
  "size_default": "text-headline-sm px-4 my-3 gap-x-3 before:w-21 before:h-8 after:h-px after:w-full"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "NoInfinityTeaserBoxTitle");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;