/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { AllasStandaloneArticleFooter as ResolvedStandaloneArticleFooter } from 'sites/allas/components/standalone/ArticleFooter';

export const ArticleFooter = ResolvedStandaloneArticleFooter;

export type ArticleFooterProps = PropsFromComponent<typeof ArticleFooter>;

export const ArticleFooterWith = (extras: DynamicStandaloneExtras): typeof ArticleFooter => {
    return function ArticleFooter(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleFooter');
        return <ResolvedStandaloneArticleFooter {...mergeProps({ options: { theme } }, props)} />;
    }
}