/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "quote_variant_primary": "text-center",
  "quote_variant_secondary": "italic",
  "quote_size_default": "text-quote-md md:text-quote-lg",
  "quote_colors_default": "",
  "quotationMark_variant_primary": "hidden",
  "quotationMark_variant_secondary": "",
  "quotationMark_size_default": "mb-4",
  "quotationMark_colors_default": "",
  "author_variant_primary": "block text-center",
  "author_variant_secondary": "inline-block",
  "author_size_default": "text-body-sm md:text-body-md mt-1.5 md:mt-2.5",
  "author_colors_default": "",
  "variant_primary": "not-prose before:block before:bg-no-repeat relative before:absolute before:right-12 before:top-4 before:md:top-0 before:md:right-8 before:-z-1",
  "variant_secondary": "flex items-start text-left",
  "size_default": "w-full before:h-full before:aspect-square mb-4 md:mb-0",
  "colors_default": "before:bg-[url(/image/quotebox-blob.svg)]"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Quotebox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;