import { http } from '@/utils/http';
import { StandaloneNewsletterSignup, StandaloneNewsletterSignupProps } from 'base/components/NewsletterSignup';
import { standaloneNewsletterSignupDefaults } from 'base/components/NewsletterSignup/NewsletterSignup.constants';
import deepmerge from 'deepmerge';
import { isProduction } from 'lib/labrador/utils';
import getUrl from 'lib/utils/getUrl';
import logger from 'lib/utils/logger';
import { FieldValues, SubmitHandler } from 'react-hook-form';

export const AllasStandaloneNewsletterSignup: typeof StandaloneNewsletterSignup = ({ inputs, options, ...props }) => {
  const fields = standaloneNewsletterSignupDefaults.inputs?.fields;

  const onSubmitValid: SubmitHandler<FieldValues> = async (data) => {
    const newsletterSignupUrl = getUrl(
      isProduction
        ? 'https://se-labrador-helper-services.labrador.allermedia.io/newsletter/signup'
        : 'https://se-labrador-helper-services-stage.labrador.allermedia.io/newsletter/signup',
    );

    if (!newsletterSignupUrl) {
      logger.error('Could not create URL for newsletter signup');
      return;
    }

    const params = new URLSearchParams({
      u: '3',
      f: '3',
      s: '',
      c: '0',
      m: '0',
      act: 'sub',
      v: '2',
      'field[7]': 'TRUE',
      jsonp: 'true',
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
    });

    if (data.newsletter.includes('allas')) {
      params.set('field[12]', 'TRUE');
      params.append('tags', 'Allas - Newsletter - Signup');
    }
    if (data.newsletter.includes('allas-diy')) {
      params.set('field[39]', 'TRUE');
      params.append('tags', 'Allas DIY & Pyssel - Newsletter - Signup');
    }
    if (data.newsletter.includes('antik-auktion')) {
      params.set('field[42]', 'TRUE');
      params.append('tags', 'Allas Antik & Auktion - Newsletter - Signup');
    }
    if (data.newsletter.includes('tradgard')) {
      params.set('field[55]', 'TRUE');
      params.append('tags', 'Allas Trädgård - Newsletter - Signup');
    }
    if (data.newsletter.includes('recept')) {
      params.set('field[48]', 'TRUE');
      params.append('tags', 'Allas Recept.se - Newsletter - Signup');
    }
    if (data.newsletter.includes('allas-shopping')) {
      params.set('field[69]', 'TRUE');
      params.append('tags', 'Allas Shopping - Newsletter - Signup');
    }
    newsletterSignupUrl.search = params.toString();

    const response = await http.get(newsletterSignupUrl.href);

    if (response?.status === 200) {
      return response;
    }

    throw response;
  };

  const checkboxes = [
    ['Allas', 'allas'],
    ['Allas DIY & Pyssel', 'allas-diy'],
    ['Antik & Auktion', 'antik-auktion'],
    ['Trädgård', 'tradgard'],
    ['Recept.se', 'recept'],
    ['Allas Shopping', 'allas-shopping'],
  ].map(([label, value]) => ({
    $standalone: { label, name: 'newsletter', value, options: { $label: { size: 'none' } } },
    rules: { required: '* Välj ett eller flera nyhetsbrev' },
  }));

  return (
    <StandaloneNewsletterSignup
      options={deepmerge<StandaloneNewsletterSignupProps['options']>(options, {
        $button: {
          size: 'large',
        },
        onSubmitValid,
      })}
      inputs={{ checkboxes, fields }}
      {...props}
    />
  );
};
