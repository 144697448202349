import { StandaloneArticleFooter } from 'base/components/ArticleFooter';
import mergeProps from 'lib/utils/mergeProps';

export const AllasStandaloneArticleFooter: typeof StandaloneArticleFooter = (props) => {
  return (
    <StandaloneArticleFooter
      {...mergeProps(
        {
          options: {
            $tags: {
              align: 'center',
            },
          },
        },
        props,
      )}
    />
  );
};
