import { JwVideoTeaser } from '@/components/JwVideo/Teaser';
import { componentTheme } from '@/styles/JwVideoTeaser';
import { LabradorComponent } from '@/types/component';

export const AllasLabradorJwVideoTeaser: LabradorComponent = ({ type, meta, data }) => {
  const { videoID, thumbnail, isFrontPage, teaserLink, title, hintText, stillImage, prominentTag } = data;

  const componentProps = {
    mediaId: videoID,
    teaserLink,
    thumbnail,
    title,
    hintText,
    isStillImage: stillImage,
    tag: prominentTag,
    ...(isFrontPage
      ? {
          options: {
            theme: componentTheme('front_page'),
            variant: 'vertical',
            $content: {
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }
      : {
          options: {
            colors: 'primary',
            variant: 'horizontal',
            $content: {
              $tag: {
                theme: componentTheme('tag'),
              },
            },
            $icon: {
              colors: 'default',
            },
          },
        }),
  };

  return <JwVideoTeaser {...componentProps} />;
};
