/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "author_variant_default": "",
  "author_colors_default": "",
  "author_size_default": "",
  "image_variant_default": "",
  "image_size_default": "w-9 h-9 mr-3",
  "image_colors_default": "",
  "text_variant_default": "",
  "text_colors_default": "",
  "text_size_default": "mr-1",
  "variant_default": "",
  "colors_default": "",
  "size_default": "text-body-md"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Byline");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;